.container {
  padding: 30px;
  height: 100%;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}

.textColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1e1f21;
}

.subtitle {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-top: 5px;
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.leftPart,
.rightPart {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rightPart {
  height: 100%;
}

.statistic,
.info {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.statisticBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 20px;
}

.statisticTextColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.statisticTitle {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  margin-bottom: 8px;
}

.statisticSum {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #344054;
  margin-bottom: 4px;
}

.statisticPeriod {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #344054;
}

.iconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: rgba(245, 243, 251, 0.5);
  border-radius: 50%;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #f5f3fb;
  border-radius: 50%;
}

.productsBanner {
  width: 100%;
  background: url('../../../../assets/bg.svg') no-repeat;
  background-size: cover;
  border-radius: 8px;
  margin-top: 22px;
}

.productsBannerTextColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 34px 24px;
  max-width: 250px;
}

.productsBannerTitle {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #150b48;
  margin-bottom: 8px;
}

.productsBannerText {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #150b48;
  margin-bottom: 33px;
}

.info {
  margin-top: 24px;
}

.infoItem {
  width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  background: url('../../../../assets/bgSmall1.svg') no-repeat;
  background-position: bottom 0px right 0;
  padding: 24px 24px 90px;
}

.infoItemTextColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 210px;
}

.infoItemTitle {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #150b48;
  margin-bottom: 16px;
}

.infoItemDescription {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #150b48;
  margin-bottom: 31px;
}

.table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #eaecf0;
  border-radius: 8px;
}

.tableTitle {
  width: 100%;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  padding: 16px 24px;
  border-bottom: 1px solid #eaecf0;
}

.tableBody {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 24px 12px;
}

.tableItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px 0 12px;
  border-bottom: 1px solid #f0f0f0;
  position: relative;
}

.tableItem:last-child {
  border-bottom: none;
}

.itemInfo {
  display: flex;
  align-items: flex-start;
  padding-left: 65px;
}

.avatar {
  position: absolute;
  top: 24px;
  left: 0;
  flex-shrink: 0;
}

.infoItemTextColumn2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.infoItemTitle2 {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin-bottom: 6px;
}

.purpleText {
  font-weight: 500;
  color: #5d4ac5;
}

.infoItemLink {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #667085;
  cursor: pointer;
}

.itemTime {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}

.emptyBlock {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyTitle {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  margin: 16px 0 4px;
}

.emptyDescription {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #667085;
  max-width: 352px;
}

.purpleLink {
  color: #5d4ac5;
  text-decoration: underline;
  cursor: pointer;
}
