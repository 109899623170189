.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 100px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  box-shadow: 0px 0px 34px rgba(66, 48, 4, 0.06);
}

.avatar {
  margin-bottom: 20px;
}

.btnGroup {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  button {
    width: 48%;
  }
}

.logoutButton {
  margin-top: 15px;
}
