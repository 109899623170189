.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}

.headerTexts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.headerTitle {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #101828;
  margin-bottom: 8px;
}

.headerSubtitle {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
}

.content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
}
