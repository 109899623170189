.layout {
  display: flex;
  width: 100%;
  height: 100vh;
}

.sider {
  height: 100%;
  background-color: #150b48;
  position: sticky;
  top: 0;
}

.content {
  flex-grow: 1;
  overflow-y: scroll;
  background-color: #fff;
}
