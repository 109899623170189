.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.infoRow {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.left,
.right {
  display: flex;
  align-items: center;
  width: 50%;
}

.infoItemTitle {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
  margin-right: 8px;
}

.infoItemValue {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  display: flex;
  align-items: center;
}

.tabs {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}

.tabProducts,
.tabAddress,
.tabProductsSelected,
.tabAddressSelected {
  padding: 10px 16px;
  background: #f9fafb;
  border: 1px solid #d0d5dd;
  cursor: pointer;
}

.tabProducts,
.tabProductsSelected {
  border-radius: 8px 0 0 8px;
}

.tabAddress,
.tabAddressSelected {
  border-left: none;
  border-radius: 0 8px 8px 0;
}

.tabAddressSelected,
.tabProductsSelected {
  color: #5d4ac5;
  background-color: white;
}

.id {
  margin-right: 14px;
}

.dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #667085;
  margin-right: 7px;
}

.dot[data-color='yellow'] {
  background-color: #fffb8f;
}

.dot[data-color='green'] {
  background-color: #389e0d;
}

.dot[data-color='red'] {
  background-color: #cf1322;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.btn {
  width: 76px;
  margin-top: 10px;
}

.spinner {
  width: initial !important;

  div {
    width: 20px !important;
    height: 20px !important;
  }
}
