.wrapper {
  width: 100%;
  position: relative;
}

.inputField,
.inputFieldWithoutBorder {
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #101828;
  padding: 10px 14px;
}

.inputField:disabled,
.inputFieldWithoutBorder:disabled {
  cursor: not-allowed;
}

.inputFieldWithoutBorder {
  border-bottom: 1px solid black !important;
  padding: 20px 0;
  border-radius: 0;
}

.inputField::placeholder,
.inputFieldWithoutBorder::placeholder {
  color: #667085;
  font-weight: 400;
}

.inputField:-webkit-autofill,
.inputFieldWithoutBorder:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.inputField:-webkit-autofill,
.inputField:-webkit-autofill:hover,
.inputField:-webkit-autofill:focus,
.inputField:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
