.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    animation: rotation 2s infinite;
    width: 52px;
    height: 52px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIQSURBVHgB7dVBaNNQGAfwf1lYSkuGLRkdFDJkdDg66dhNmKhMFOQxPHgbCN4Ej548iqDXCR48edhJUVGmCAoBB4PBYINCYWWhjJaFbbSsI6WjLQnde4UeNjr60nY7fT8olNeUly/f938BCCGEEEIIIYQQQgghhBBCCCGEEELI1XDdJms2mwxXoFbzv1dA9kJRyMflDDbSh1CHhjA/F8eThQQUJfAbA7aTc9jy920U7Aoimopni0nMTEWl9pEu6OtKjv0yd8+sxfQQFh8nMHNzdCBFOVWX/fhjwVyzz6yLB7j05jbCYaXrPtIFvV3aZNndcsffEtcjeHTXwGxKRyDgv2PFoxoz/xdgrtuoN7yO17x6MYupye5dUiBpbCyEiwqy+Pp7/tFCKj593mbJGzoShoZoNNjxBsT45vk4Za0y0pkSXr5eQzcxvr8M6Q45jsvefdiEfViR/UtrVHQ+lsOqwr8DJw0XFaeBcqUOP8RYP7w3PtgMCeLJflux8HfVhgcPl01k9PnTaUyMj0iPsa+C2vb2q+zLTwvpbAmXQYzuwgMD8zyXis9M9lRQWy7vsH9mHhs8B57Xf8dER+7PGbhzK45gsLfXQV8FtVVrLtvaKrYCnrGOeVbkM2LENKSmdaSSo5icGOn7+B9IQecVi1VWODhBqVSHww+AussT56F1MITCKiLXhnk3wjDiWs+duMgpp7q3RJixVyYAAAAASUVORK5CYII=');
    flex-shrink: 0;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}