.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 12px 0;
  background-color: white;
}

.bigBtn {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 36px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  cursor: pointer;
  margin: 0 24px;
}

.bigBtn[data-is-disabled='true'] {
  cursor: not-allowed;
}

.bigBtn[data-is-disabled='true'] span {
  color: #d5d3eb !important;
}

.prev,
.next {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.next {
  margin-right: 8px;
}

.prev {
  margin-left: 8px;
}

.icon {
  display: flex;
  font-size: 16px;
}

.desktopInfo {
  display: none;
}

.infoItem,
.infoItemLast,
.infoItemSelected,
.infoItemDots {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: 0.2s ease background-color;
}

.infoItem[data-is-active='true'] {
  background: rgba(93, 74, 197, 0.05);
  border-radius: 8px;
  color: #5d4ac5;
  border: none;
}

.infoItem:not(:last-child) {
  margin-right: 10px;
}

.infoItem:hover {
  background: rgba(94, 109, 188, 0.05);
  border: none;
}

.infoItem:hover > span {
  color: var(--blue-2-color) !important;
}

.infoItemDots {
  border: none;
  cursor: initial;
  margin-right: 10px;
}

.infoItemLast {
  cursor: initial;
}

@media (min-width: 1199px) {
  .bigBtn[data-is-disabled='false']:hover {
    background: rgba(94, 109, 188, 0.05);
    border: none;
  }

  .bigBtn[data-is-disabled='false']:hover span {
    color: var(--blue-2-color) !important;
  }

  .bigBtn {
    width: 111px;
  }

  .desktopInfo {
    display: flex;
    align-items: center;
    margin: 0 10px;
  }
}
