.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  border: 1px solid #eaecf0;
  border-radius: 6px;
}

.cardHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #f2f4f7;
}

.left,
.right {
  display: flex;
  align-items: center;
}

.icon {
  border: 1px solid #f2f4f7;
  border-radius: 4px;
  padding: 4px 10px 5px 9px;
  margin-right: 12px;
}

.title {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #150b48;
}

.badge {
  display: flex;
  align-items: center;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #5d4ac5;
  background: #f5f3fb;
  border-radius: 16px;
  padding: 2px 8px;
  margin-left: 16px;

  &::before {
    content: '';
    display: flex;
    width: 6px;
    height: 6px;
    background-color: #5d4ac5;
    border-radius: 50%;
    margin-right: 7px;
  }
}

.edit,
.delete {
  cursor: pointer;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #5d4ac5;
}

.delete {
  color: #667085;
  margin-left: 12px;
}

.infoItem {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.infoTitle,
.infoValue {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
}

.infoValue {
  font-weight: 400;
  color: #667085;
  margin-left: 12px;
}
