.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 32px;
}

.title {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #101828;
  margin-bottom: 16px;
}

.statistic {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  margin-bottom: 28px;
}

.statisticBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 20px;
}

.statisticTextColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.statisticTitle {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  margin-bottom: 8px;
}

.statisticSum {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #344054;
  margin-bottom: 4px;
}

.statisticPeriod {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #344054;
}

.iconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: rgba(245, 243, 251, 0.5);
  border-radius: 50%;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #f5f3fb;
  border-radius: 50%;
}

.tabsBox {
  width: 100%;
}
