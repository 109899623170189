.upload,
.uploadBig {
  border: 1px dashed #eaecf0;
  border-radius: 8px;
  width: 128px;
  min-width: 128px;
  max-width: 128px;
  height: 128px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadBig {
  width: 100%;
  min-width: 100%;
}

.firstCircle,
.secondCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background-color: #f9fafb;
  border-radius: 50%;
}

.secondCircle {
  width: 32px;
  height: 32px;
  background-color: #f2f4f7;
}

.textRow {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.uploadText,
.uploadTextSingle {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #5d4ac5;
}

.uploadTextSingle {
  margin-top: 12px;
}

.uploadText2 {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-left: 8px;
}

.uploadText3 {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #667085;
  margin-top: 4px;
}
