.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 176px auto 270px;
  max-width: 352px;
}

.text {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.25);
  margin-top: 14px;
}

.emptyBoxTitle {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  margin: 16px 0 4px;
}

.emptyBoxSubtitle {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #667085;
  margin-bottom: 24px;
}
