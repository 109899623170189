.socialItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 14px;
}

.iconWrap,
.icon {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9fafb;
  border-radius: 50%;
  flex-shrink: 0;
}

.icon {
  width: 30px;
  height: 30px;
  background-color: #f2f4f7;
}

.socialText {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10px;
  cursor: pointer;
}

.socialTitle {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.socialSubitle {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}

.inputBox {
  width: 100%;
  margin: 0 10px;
}

.wrapper {
  display: flex;
  align-items: center;
}

.removeIcon,
.editIcon {
  cursor: pointer;
  padding: 10px;
}

.btnBox {
  min-width: 95px;
  flex-shrink: 0;
}
