#root {
  width: 100%;
  height: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// LAYOUT

.ant-layout {
  background-color: white;
}

.ant-layout.ant-layout-has-sider {
  min-height: 100vh;
}

.ant-menu-item-selected {
  border-left: 2px solid #5d4ac5;
  background: linear-gradient(89.95deg, rgba(93, 74, 197, 0.27) 0.87%, rgba(93, 74, 197, 0) 86.75%) !important;
  border-radius: 0 !important;
}

// FORM

.ant-form-vertical .ant-form-item-label {
  padding: 0;
}

// WYSWYG

.ql-toolbar.ql-snow {
  text-align: left;
}

// INPUT

.ant-input {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  padding: 10px 14px;
  max-height: 44px;
}

.ant-input::placeholder {
  color: #667085;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #9092b7;
  box-shadow: none;
}

.ant-input:hover {
  border-color: #9092b7;
}

.ant-input[disabled] {
  background: rgba(94, 109, 188, 0.05);
  border: 1px solid #d5d3eb;
  border-radius: 5px;
  color: var(--gray-text-color-2);
}

.ant-input-prefix {
  margin-right: 10px !important;
}

.ant-input-affix-wrapper {
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #101828;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-height: 44px;
  min-height: 44px;
  padding: 10px 14px;
}

.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:active {
  border-color: #5e6dbc;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #5e6dbc;
  border-right-width: none;
}

.ant-input-affix-wrapper-focused {
  border-color: #5e6dbc;
  box-shadow: none;
}

// SELECT

.ant-select {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #101828;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;

  max-height: 44px;
}

.ant-select-selector {
  padding: 0 14px !important;
  height: 44px !important;
  background-color: transparent !important;
  &:hover {
    border-color: #5d4ac5 !important;
    box-shadow: none !important;
  }
  &:focus {
    border-color: #5d4ac5 !important;
    box-shadow: none !important;
  }
  &:focus-within {
    border-color: #5d4ac5 !important;
    box-shadow: none !important;
  }
  &:active {
    border-color: #5d4ac5 !important;
    box-shadow: none !important;
  }
}

.ant-select-selector .ant-select-selection-search-input {
  height: 44px !important;
}

.ant-select-single.ant-select-lg .ant-select-selector .ant-select-selection-item {
  line-height: 44px !important;
}

// MODAL

.ant-modal-header {
  border: none;
}

.ant-modal-title {
  font-family: 'Inter' !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 34px !important;
  color: #101828 !important;
}

.ant-modal-footer button {
  width: 90px;
}

//TAG

.ant-tag {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 16px;
  border: none;
}

// BUTTON

.ant-btn {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px !important;
  line-height: 20px;
  color: #344054;
  flex-shrink: 0;
  width: 100%;

  &:hover,
  &:focus-visible,
  &:active,
  &:visited {
    border-color: #5d4ac5 !important;
    color: #5d4ac5 !important;
  }
}

.ant-btn-primary {
  background: #5d4ac5;
  border: 1px solid #5d4ac5;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;

  &:hover,
  &:focus-visible,
  &:active,
  &:visited {
    background: darken($color: #5d4ac5, $amount: 5%) !important;
    color: #ffffff !important;
  }
}

.ant-btn-dashed {
  background: #f9f5ff;
  border: 1px solid #f9f5ff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: #5d4ac5;

  &:hover,
  &:focus-visible,
  &:active,
  &:visited {
    background: darken($color: #f9f5ff, $amount: 5%) !important;
    border: 1px solid #f9f5ff !important;
    color: #5d4ac5 !important;
  }
}

// UPLOAD

.ant-upload.ant-upload-select {
  border: none !important;
  background: white !important;
  width: 100% !important;
  height: 100% !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}

.ant-upload-list-item-container {
  width: 100% !important;
}

//DRAWER

.ant-drawer .ant-drawer-header {
  border-bottom: none;
  padding-bottom: 0;
}

.ant-drawer-title {
  font-family: 'Inter';
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 34px !important;
  color: #101828 !important;
}

// TABLE

.ant-table {
  border-radius: 6px;
}

.ant-table-cell {
  background-color: white !important;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #667085 !important;
}

.ant-table-wrapper .ant-table-summary {
  background: red !important;
}

// CHECKBOX

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #5d4ac5;
  border-color: #5d4ac5;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #5d4ac5 !important;
  border-color: #5d4ac5 !important;
}

.ant-checkbox-checked:after {
  border-color: #5d4ac5 !important;
}

// AVATAR

.ant-avatar-group > *:first-child {
  z-index: 2;
}

.ant-avatar-group > *:not(:first-child) {
  margin-inline-start: -30px !important;
  z-index: 1;
}

// TABS

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5d4ac5;

  &:hover {
    color: #5d4ac5 !important;
  }
}

.ant-tabs .ant-tabs-tab {
  &:hover {
    color: #5d4ac5 !important;
  }
}

.ant-tabs .ant-tabs-ink-bar {
  background: #5d4ac5;
}

// DATE PICKER

.ant-picker-range {
  min-height: 40px;
}

.ant-picker-focused {
  border-color: #5d4ac5;
  box-shadow: 0 0 0 2px rgb(93 74 197 / 10%);

  &:hover {
    border-color: #5d4ac5;
  }
}

.ant-picker-active-bar {
  background: #5d4ac5 !important;
}

:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown
  .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown .ant-picker-cell-selected)
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown
  .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown .ant-picker-cell-range-start)
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown
  .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown .ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  background-color: #5d4ac5 !important;
}

:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown
  .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown .ant-picker-cell-selected)
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown
  .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown .ant-picker-cell-range-start)
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown
  .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown .ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  background-color: #5d4ac5 !important;
}
:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown
  .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown .ant-picker-cell-range-start):not(
    :where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown .ant-picker-cell-range-start-single
  )::before,
:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown
  .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown .ant-picker-cell-range-end):not(
    :where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown .ant-picker-cell-range-end-single
  )::before {
  background: #f9f5ff !important;
}

:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown
  .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown .ant-picker-cell-in-range)
  > div {
  background-color: #f9f5ff !important;
}

:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown
  .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown .ant-picker-cell-in-range)::before {
  background: #f9f5ff !important;
}

:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown
  .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown .ant-picker-cell-today)
  .ant-picker-cell-inner::before {
  border-color: #5d4ac5;
}

:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-range:hover {
  border-color: #5d4ac5;
}

:where(.css-dev-only-do-not-override-9ntgx0).ant-notification-notice .ant-notification-notice-description {
  color: rgba(0, 0, 0, 0.88);
}
