.container {
  width: 100%;
  display: flex;

  align-items: flex-start;
}

.logo {
  flex-shrink: 0;
  width: 262px;
  height: 266px;
  margin-right: 14px;
}

.textSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
}

.textStrong {
  font-weight: 600;
}
