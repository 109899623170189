.container {
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #eaecf0;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 672px;
}

.textColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1e1f21;
}

.subtitle {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-top: 5px;
}

.btnGroup {
  display: flex;
  align-items: center;

  button {
    width: 88px;
    margin-left: 12px;
  }
}

.formItemRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin-bottom: 6px;
}

.labelSubtitle {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}

.labelLink {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5d4ac5;
  cursor: pointer;
}

.field {
  display: flex;
  align-items: flex-start;
}

.divider {
  height: 1px;
  background: #eaecf0;
  margin-bottom: 20px;
}

.inputBox {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #eaecf0;
  padding-top: 16px;
}

.isActive {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

div.isActiveFormItem {
  margin: 0;
}
