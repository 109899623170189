.container {
  height: 100%;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}

.textColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1e1f21;
}

.subtitle {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
  margin-top: 8px;
}

.btn {
  background: #5d4ac5 !important;
  border: 1px solid #5d4ac5 !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px !important;

  &:focus-visible,
  &:focus,
  &:hover,
  &:visited,
  &:active {
    background: darken($color: #5d4ac5, $amount: 0.1) !important;
    border: 1px solid #5d4ac5;
  }
}

.grayText,
.darkText,
.darkTextThin {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
}

.darkText {
  color: #101828;
}

.darkTextThin {
  color: #101828;
  font-weight: 400;
}

.table {
  border: 1px solid #eaecf0;
  border-radius: 6px;
}

.dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #667085;
  margin-right: 7px;
}

.dot[data-color='yellow'] {
  background-color: #fffb8f;
}

.dot[data-color='green'] {
  background-color: #389e0d;
}

.dot[data-color='red'] {
  background-color: #cf1322;
}

.paymentMethod {
  display: flex;
  align-items: center;

  & > img {
    width: 34px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 12px;
  }
}

.cardInfo {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}

.btnGroup {
  display: flex;
  align-items: center;
}

.btn {
  width: 140px;
  margin-left: 12px;
}
