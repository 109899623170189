.table {
  border: 1px solid #eaecf0;
  border-radius: 6px;
}

.profit {
  color: #027a48;
}

.summaryCell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sum,
.sumRed,
.sumGreen {
  margin-top: 12px;
}

.sumRed {
  color: #f04438;
}

.sumGreen {
  color: #027a48;
}
