.logo {
  margin: 0 auto 20px;
  width: 77px;
  height: 27px;
}

.container {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #150b48;
  min-height: 100vh;
}

.container {
  ul {
    background-color: #150b48 !important;

    li {
      color: white !important;
      text-align: left;
    }
  }
}

.upper {
  flex: 1 1 auto;
}

.userSection {
  width: 100%;
  max-width: 230px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 5px 14px 10px;
  background: linear-gradient(143.35deg, rgba(255, 255, 255, 0.1) -7.76%, rgba(255, 255, 255, 0.06) 110.61%);
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin: 16px auto 56px;
}

.userInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 11px;
}

.textWrapper {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.avatar {
  flex-shrink: 0;
}

.email,
.username {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  text-align: left;
}

.username {
  margin-left: 14px;
}

.iconsBox {
  display: flex;
  position: relative;
  margin-left: 6px;
}

.dropdownIcon {
  cursor: pointer;
}

.dropdownBox {
  position: absolute;
  left: -55px;
  top: 48px;
  background: #160b4b;
  box-shadow: 1px 3px 25px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 20px;
  min-width: 257px;
  z-index: 99;
}

.boxItem {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
}

.dropdownBoxTitle {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.85);
  text-align: left;
  margin-bottom: 16px;
}

.logoutBox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid;
  border-image: linear-gradient(to right, #5d4ac545, #5d4ac500) 1 0 0 0;
  padding: 24px;
}

.logoutBoxEmail {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #d0d5dd;
  word-break: break-word;
}

.logout {
  flex-shrink: 0;
  margin-left: 10px;
  cursor: pointer;
}
