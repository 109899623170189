.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
  padding: 40px 30px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  box-shadow: 0px 0px 34px rgba(66, 48, 4, 0.06);
}

.form {
  width: 400px;
}

.btn {
  width: 200px;
}
