.container {
  padding: 30px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}

.textColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1e1f21;
}

.subtitle {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-top: 5px;
}

.grayText,
.darkText,
.profit,
.view {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
}

.darkText {
  color: #101828;
}

.profit {
  color: #038750;
}

.view {
  color: #5d4ac5;
  font-weight: 600;

  &:hover,
  &:focus-visible,
  &:active,
  &:visited {
    color: #5d4ac5;
  }
}

.productsListTable {
  border: 1px solid #eaecf0;
  border-radius: 6px;
}
