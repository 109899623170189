.formItemRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.formItemRowSingle {
  width: 100%;
}

.formItem {
  width: 48%;
}

.label {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin-bottom: 6px;
}

.btnGroup {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
  border-top: 1px solid #f0f0f0;
}

.btn {
  width: 140px;

  &:last-child {
    margin-left: 8px;
  }
}

.info {
  display: flex;
  align-items: flex-start;
  margin-bottom: 37px;
}

.infoText {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-left: 8px;
}
